<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="D_NOTIFIER"
      tooltip-title="D_NOTIFIER"
      :display-breadcrumb="true"
    >
      <template v-slot:filterItems>
        <h2 v-i18n="result" class="mb-3 text-text-color font-roboto font-normal text-xl">Search</h2>
      </template>
    </TitleWrapper>
    <div
      class="flex flex-col p-5 border border-primary-gray-200 content-col bg-white rounded-lg text-base capitalize md:mx-0 mx-5 mt-5"
    >
      <div class="py-3 flex">
        <span class="w-40">Title:</span>
        <span class="text-menu">
          {{ announcementData.title }}
        </span>
      </div>
      <div class="py-3 flex">
        <span class="w-40">Announcement Type:</span>
        <span class="text-menu">
          {{ announcementData.announcement_type && announcementData.announcement_type.title }}
        </span>
      </div>
      <div class="py-3 flex">
        <span class="w-40">Role:</span>
        <span class="text-menu">{{ announcementData.role }}</span>
      </div>
      <div class="py-3 flex">
        <span class="w-40">Date:</span>
        <span class="text-menu">
          {{ $filters.dateFormatInUTC(announcementData.inserted_at, TIME_CONSTANTS.DATE) }}
        </span>
      </div>
      <div v-if="announcementData.email_body" class="py-3 pb-6 flex">
        <span class="w-24">Email Body:</span>
        <div class="text-menu">
          {{ announcementData.email_body }}
        </div>
      </div>
      <div v-if="announcementData.webpush_body" class="py-3 pb-6 flex">
        <span class="w-24">Description:</span>
        <div class="text-menu">
          {{ announcementData.webpush_body }}
        </div>
      </div>
      <div v-if="announcementData.message_body" class="py-3 pb-6">
        <span class="w-24">Message Body:</span>

        <div class="mt-2 leading-8 tracking-wider font-normal text-menu">
          {{ announcementData.message_body }}
        </div>
      </div>
    </div>
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div v-else-if="!isLoading && isEmpty(notificationUserList)">
      <NoRecordFound />
    </div>
    <div v-else class="md:mx-0 mx-5">
      <Heading heading="Receiver List" />
      <div class="border relative border-primary-grey rounded-lg">
        <div
          id="table-container"
          :class="[leftBlurArea, rightBlurArea]"
          class="overflow-x-auto rounded-lg"
          @scroll.passive="scrollDetect"
        >
          <table class="w-full table-auto whitespace-nowrap">
            <thead class="bg-bright-gray">
              <tr class="ltr:text-left">
                <th
                  v-for="(head, index) in tableHeaders"
                  :key="index"
                  class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pr-4 rtl:pl-0 ltr:pl-4 ltr:pr-0"
                >
                  <span
                    class="ltr:border-r rtl:border-l border-primary-grey w-full block rtl:pl-3 ltr:pr-3"
                  >
                    {{ head }}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(notification, index) in notificationUserList"
                :key="index"
                class="border-t border-primary-grey text-left"
              >
                <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-2">
                  <span>
                    <SingleUserDisplay
                      :user="notification.receiver"
                      label="full_name"
                      :dropdown-top-bottom="
                        index === notificationUserList.length - 1 ? 'bottom-6' : 'top-6'
                      "
                      :image="true"
                      :tooltip="
                        index === notificationUserList.length - 1 ? 'tooltip-bottom' : 'tooltip-top'
                      "
                    />
                  </span>
                </td>
                <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-2">
                  <span class="font-roboto font-normal text-text-color text-sm">
                    {{ notification.receiver.email || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                  </span>
                </td>
                <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-2">
                  <span class="font-roboto font-normal text-text-color text-sm">
                    {{ notification.receiver.phone || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                  </span>
                </td>
                <td
                  class="ltr:border-r relative rtl:border-l border-primary-grey rtl:text-right px-3 py-2"
                >
                  <EmailStatus :key="index" :notification="notification" />
                </td>

                <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-2">
                  <div
                    class="font-roboto font-normal text-text-color text-sm px-3 py-2 rounded-md text-center"
                    :style="getMediumStyle(notification.sms)"
                  >
                    {{
                      (notification.sms && notification.sms.status) ||
                      GENERAL_CONSTANTS.NOT_APPLICABLE
                    }}
                  </div>
                </td>
                <td
                  class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-2 text-center relative"
                >
                  <WebPushStatus :key="index" :notification="notification" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Pagination
      v-if="totalNotificationCount > 10"
      v-show="!isLoading"
      :key="`ForceRender${forceRender}`"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(totalNotificationCount, filteredRecordLimit)"
      @filterRecord="_getAnnouncements"
    />
    <SingleNotificationModal></SingleNotificationModal>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import Loader from '@components/BaseComponent/Loader.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import generalMixin from '@src/mixins/general-mixins.js'
import { transformTextToCapitalize } from '@utils/generalUtil'
import SingleNotificationModal from '@/src/router/views/admin/modals/AnnouncementUpsertModal.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import { getTimeFromDateWithUTC } from '@utils/moment.util'
import TIME_CONSTANTS from '@src/constants/date-time-constants'
import GENERAL_CONSTANTS, { COMMUNICATION_MEDIUMS } from '@src/constants/general-constants'
import scrollMixin from '@src/mixins/scroll-mixin'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import Heading from '@src/components/Heading.vue'
import EmailStatus from '@src/router/views/notifications/DetailView/Email.vue'
import WebPushStatus from '@src/router/views/notifications/DetailView/WebPush.vue'
import { getMediumStyle } from '@src/utils/filters/announcements/index.js'

export default {
  components: {
    SingleUserDisplay,
    Loader,
    TitleWrapper,
    SingleNotificationModal,
    Pagination,
    NoRecordFound,
    EmailStatus,
    WebPushStatus,
    Heading,
  },
  mixins: [generalMixin, scrollMixin],
  data() {
    return {
      TIME_CONSTANTS,
      forceRender: 0,
      result: 'result',
      title: 'title',
      dashboard: 'dashboard',
      totalNotificationCount: 0,
      announcementId: this.$route.params.announcementId,
      options: ['Select'],
      tableHeaders: [
        'User Name',
        'Email',
        'Phone',
        'Email Status',
        'Phone Status',
        'Web Push Status',
      ],
      announcementData: {},
      notificationUserList: [],
      isLoading: false,
      noRecord: false,
      filteredRecordLimit: 10,
      GENERAL_CONSTANTS,
      actionList: [{ name: 'SNA_RET' }, { name: 'SNA_RES' }, { name: 'SNA_DUP' }],
      isEmpty,
    }
  },
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
    }),
  },
  created() {
    this._getAnnouncements()
  },
  methods: {
    getMediumStyle,
    async _getAnnouncements(range) {
      const payload = {
        range: {
          skip: range?.skip || this.GENERAL_CONSTANTS.RECORD_SKIP,
          limit: range?.limit || this.GENERAL_CONSTANTS.RECORD_LIMIT,
        },
      }
      payload.announcementId = this.announcementId
      this.isLoading = true
      const [res, err] = await this.getAnnouncemntById(payload)
      this.isLoading = false
      if (err) return
      this.announcementData = res.data.announcement_data
      this.announcementData.role = transformTextToCapitalize(this.announcementData.receiver_role_id)
      this.totalNotificationCount = res.data.meta.total_records
      this.notificationUserList = res.data.records.map((rec) => {
        rec.receiver.full_name = `${rec.receiver.first_name} ${rec.receiver.last_name}`
        rec.receiver_mediums_data.forEach((med) => {
          if (med.medium === COMMUNICATION_MEDIUMS.WEBPUSH) {
            rec.webpush = med
          }
          if (med.medium === COMMUNICATION_MEDIUMS.SMS) {
            rec.sms = med
          }
          if (med.medium === COMMUNICATION_MEDIUMS.EMAIL) {
            rec.email = med
          }
        })
        return rec
      })
    },

    ...mapActions('notifications', ['getAnnouncemntById']),
    ...mapActions('layout', ['setRightbarContent', 'setRightBarStats', 'setShowModal']),
  },
}
</script>

<style lang="scss" module>
.widthItem {
  min-width: 50%;
}
.minHieght80 {
  min-height: 80vh;
}
</style>
