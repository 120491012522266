export const getMediumStyle = (status) => {
  if (typeof status !== 'object') return
  const style = {
    color: '',
    backgroundColor: '',
  }

  switch (status.status) {
    case 'sent':
      style.color = '#267C26'
      style.backgroundColor = '#E9F2E9'
      break
    case 'failed':
      style.color = '#EA3535'
      style.backgroundColor = '#FCE1E1'
      break
    case 'halted':
      style.color = '#EA3535'
      style.backgroundColor = '#FCE1E1'
      break
    case 'pending':
      style.color = '#FFC201'
      style.backgroundColor = '#FFF9E6'
      break
  }

  return style
}
