<template>
  <div>
    <div
      class="font-roboto font-normal text-text-color text-sm px-3 py-2 rounded-md"
      :style="getMediumStyle(notification.webpush)"
      @mouseover="toggleToolTip"
      @mouseleave="toggleToolTip"
    >
      {{
        (notification.webpush && notification.webpush.status) || GENERAL_CONSTANTS.NOT_APPLICABLE
      }}
    </div>

    <UiTooltip
      v-if="showTooltip"
      :title="notification?.webpush?.failed_reason"
      :description="
        $filters.formattedDate(
          notification.webpush.last_attempted_at,
          DATE_TIME_CONSTANT.DATE_TIME_12,
        )
      "
      custom-classes="top-12"
    />
  </div>
</template>

<script>
import UiTooltip from '@src/components/UiSelectTooltip.vue'
import { getMediumStyle } from '@src/utils/filters/announcements/index.js'
import DATE_TIME_CONSTANT from '@src/constants/date-time-constants.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

export default {
  components: {
    UiTooltip,
  },
  props: {
    notification: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      DATE_TIME_CONSTANT,
      GENERAL_CONSTANTS,
      showTooltip: false,
    }
  },
  methods: {
    getMediumStyle,
    toggleToolTip() {
      this.showTooltip = !this.showTooltip
    },
  },
}
</script>
